import css from './Price.module.css'
import img1 from './img/1.png'
import img2 from './img/2.png'
import {Link} from 'react-scroll'


const Price = () => {
  return (
<section className={css.container} id='price'>

    <h2 className={css.header}>Тарифы</h2>
    <div className={css.card} id='price1'>
        <div className={css.imgContainer}>
            <img src={img1} className={css.cardImg}/>
            <h3 className={css.cardHeader}>Трезвый водитель</h3>
            <Link to="contacts"  spy={true} smooth={true} offset={-200} duration={1500} className={css.button}>Заказать</Link>
        </div>
        <div className={css.price1Container}>
          <h3 className={css.priceHeader}>Прайс</h3>
          <span className={css.priceType}>Город</span>
          <span className={css.priceDecr}>Подача водителя <b>1000р</b><br/>далее <b>25р.мин.</b></span>
          <span className={css.priceType}>Загород</span>
          <span className={css.priceDecr}>Подача водителя <b>1500р</b><br/>далее <b>40р.км.</b></span>
        </div>
        <div className={css.price2Container}>
        <h3 className={css.priceHeader}>Праздники</h3>
          <span className={css.priceType}>Город</span>
          <span className={css.priceDecr}>Подача водителя <b>1500р</b><br/>далее <b>25р.мин.</b></span>
          <span className={css.priceType}>Загород</span>
          <span className={css.priceDecr}>Подача водителя <b>2000р</b><br/>далее <b>40р.км.</b></span>
        </div>
    </div>
    <div className={css.card} id='price2'>
        <div className={css.imgContainer}>
            <img src={img2} className={css.cardImg}/>
            <h3 className={`${css.cardHeader} ${css.cardHeder2}`}>Перегон авто</h3>
            <h3 className={`${css.cardHeader} ${css.cardHeder2}`}>&</h3>
            <h3 className={`${css.cardHeader} ${css.cardHeder2}`}>Водитель на день</h3>

        </div>
        <div className={css.price3Container}>
          <span className={css.priceDecription}>Позвоните нам, назовите начальный и конечный адрес, далее водитель сориентирует вас.</span>
          <Link to="contacts"  spy={true} smooth={true} offset={-200} duration={1500} className={`${css.button} ${css.btn1}`}>Заказать</Link>
        </div>

    </div>
</section>
  )
}

export default Price
